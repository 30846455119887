import React from "react";

const Login = () => {
  return (
    <div>
      <h1>Login Owner Feel</h1>
      <p>Enter your credentials to access the dashboard.</p>
    </div>
  );
};

export default Login;
